import React, { FC, useEffect, useState } from 'react';
import { GridRowId } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import PollIcon from '@mui/icons-material/Poll';
import ListIcon from '@mui/icons-material/List';
import { Alert, AlertColor, Backdrop, BottomNavigation, BottomNavigationAction, Box, CircularProgress, Paper } from '@mui/material';
import User from '../../Models/Interfaces/User';
import Product from '../../Models/Interfaces/Product';
import Certificate from '../../Models/Interfaces/Certificate';
import ReferenceData from '../../Models/Interfaces/ReferenceData';
import Header from '../../SharedComponents/Header/Header';
import { DividingContainer, HomeWrapper } from './Home.styled';
import ProductModal from './Components/ProductModal/ProductModal';
import LandingModal from './Components/LandingModal/LandingModal';
import CertificateModal from './Components/CertificateModal/CertificateModal';
import CertificateOverview from './Components/CertificateOverview/CertificateOverview';
import CatchCertificateList from './Components/CatchCertificateList/CatchCertificateList';
import { getCertificateById, getCertificates, getCurrentUser, getReferenceData, getExporterAddresses, getReceiverAddresses } from '../../Services/VeidivottordAPIService';
import Address from '../../Models/Interfaces/Address';

interface HomeProps { }

const Home: FC<HomeProps> = () => {

  const [certificates, setCertificates] = useState<Certificate[] | null>([]);
  const [selectedCertificate, setSelectedCertificate] = useState<Certificate | null>({} as Certificate);
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const [exporterAddresses, setExporterAddresses] = useState<Address[]>([]);
  const [receiverAddresses, setReceiverAddresses] = useState<Address[]>([]);
  const [certificateModalOpen, setCertificateModalOpen] = useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [landingModalOpen, setLandingModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarType, setSnackbarType] = useState<AlertColor>('success');
  const [certificatesLoading, setCertificatesLoading] = useState(false);
  const [referenceData, setReferenceData] = useState<ReferenceData>();
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined);
  const [modalActionType, setModalActionType] = useState<string | undefined>(undefined);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [navigationValue, setNavigationValue] = React.useState(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const [inputToFocus, setInputToFocus] = useState<string | undefined>(undefined);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleLandingModal = () => setLandingModalOpen(!landingModalOpen);
  const toggleCertificateModal = (actionType?: string, inputToFocus?: string) => {
    setCertificateModalOpen(!certificateModalOpen);
    setModalActionType(actionType);
    setInputToFocus(inputToFocus);
  }
  const toggleProductModal = (actionType?: string) => {
    setProductModalOpen(!productModalOpen);
    setModalActionType(actionType);
  }

  const fetchCurrentUser = async () => {
    const currentUser = await getCurrentUser(setLoadingState);
    setUserInfo(currentUser);
  }

  const fetchReferenceData = async () => {
    const referenceDataResponse = await getReferenceData();
    setReferenceData(referenceDataResponse);
  }

  const fetchAddresses = async () => {
    const exporterAddresses = await getExporterAddresses();
    const receiverAddresses = await getReceiverAddresses();
    setExporterAddresses(exporterAddresses);
    setReceiverAddresses(receiverAddresses);
  }

  const loadData = async () => {
    fetchCurrentUser();
    await fetchCertificates();
    await fetchReferenceData();
    await fetchAddresses();
  }

  useEffect(() => {
    loadData();
  }, []);

  const openSnackbar = (statusCode: number) => {
    if (statusCode === 201 || statusCode === 200) {
      setSnackbarType('success');
      setSnackbarOpen(true);
    } else {
      setSnackbarType('error');
      setSnackbarOpen(true);
    }
  }

  const setLoadingState = (loadingState: boolean) => {
    setLoading(loadingState);
  }

  const handleClose = () => {
    setSnackbarOpen(false);
  }

  const fetchCertificates = async (fromDate?: Date) => {
    setCertificatesLoading(true);
    const fetchedCertificates = await getCertificates(setLoadingState, fromDate);
    setCertificatesLoading(false);
    setCertificates(fetchedCertificates);
    setInitialLoad(false);

    if (fetchedCertificates?.length && selectedCertificate?.id === undefined) {
      await fetchCertificate(fetchedCertificates[0].id);
    }
  }

  const desktopView = windowDimensions.width > 1200;
  const fetchCertificate = async (certificateId: number, onConfirm?: boolean) => {
    const fetchedCertificate = await getCertificateById(setLoadingState, certificateId);
    setSelectedCertificate(fetchedCertificate);

    // Update certificate status in certificate list on confirm / rectify.
    const certificateListItem = certificates?.find(certificate => certificate.id === certificateId);

    if (onConfirm) {

      // Find the previous confirmed certificate and update its status in the certificate list.
      const previousConfirmedCertificate= fetchedCertificate.rectificationHistory.reverse().find(certificate => certificate.status.id === 3);

      if (previousConfirmedCertificate) {
        const previousConfirmedCertificateListItemIndex = certificates?.findIndex(certificate => certificate.id === previousConfirmedCertificate.id);
        if (previousConfirmedCertificateListItemIndex && certificates) {
          certificates[previousConfirmedCertificateListItemIndex].status = { id: 3, description: 'Ógilt' };
        }
      }
    }

    if (certificateListItem) {
      if (certificateListItem?.status !== fetchedCertificate?.status) {
        certificateListItem.status = fetchedCertificate?.status;
      }
      if (certificateListItem?.dateCreated !== fetchedCertificate?.dateCreated) {
        certificateListItem.dateCreated = fetchedCertificate?.dateCreated;
      }
    }

    if ((navigationValue === 0 && desktopView) || !initialLoad) {
      setNavigationValue(1);
    }
  }


  const handleProductSelect = (productId: GridRowId | undefined) => {
    const product = selectedCertificate?.products.find(product => product.id === productId);
    setSelectedProduct(product);
  }

  // If not logged in show empty page.
  if (userInfo === null) {
    return (<></>);
  }

  return (
    <React.Fragment>
      <Header
        desktopView={desktopView}
      />
      <HomeWrapper style={{ height: desktopView ? 'calc(100% - 64px)' : 'calc(100% - 50px)' }}>
        <DividingContainer>
          {(desktopView || (!desktopView && navigationValue === 0)) &&
            (
              <CatchCertificateList
                certificates={certificates}
                selectedCertificate={selectedCertificate}
                toggleCertificateModal={toggleCertificateModal}
                fetchCertificate={fetchCertificate}
                certificatesLoading={certificatesLoading}
                width={(!desktopView && navigationValue === 0) ? '100%' : '25%'}
                windowDimensions={windowDimensions}
                fetchCertificates={fetchCertificates}
                userInfo={userInfo}
              />
            )
          }

          {(desktopView || (!desktopView && navigationValue === 1)) &&
            <CertificateOverview
              selectedCertificate={selectedCertificate}
              toggleProductModal={toggleProductModal}
              toggleLandingModal={toggleLandingModal}
              toggleCertificateModal={toggleCertificateModal}
              setLoadingState={setLoadingState}
              openSnackbar={openSnackbar}
              handleProductSelect={handleProductSelect}
              selectedProduct={selectedProduct}
              fetchCertificate={fetchCertificate}
              fetchCertificates={fetchCertificates}
              modalActionType={modalActionType}
              windowDimensions={windowDimensions}
              navigationValue={navigationValue}
              setNavigationValue={setNavigationValue}
            />
          }
        </DividingContainer>

        {(!desktopView) &&
          <Box>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
              <BottomNavigation
                showLabels
                value={navigationValue}
              >
                <BottomNavigationAction label="Vottorðalisti" icon={<ListIcon />} onClick={() => setNavigationValue(0)} />
                <BottomNavigationAction label="Veiðivottorð" icon={<PollIcon />} onClick={() => setNavigationValue(1)} />
              </BottomNavigation>
            </Paper>
          </Box>
        }
      </HomeWrapper>

      <CertificateModal
        toggleCertificateModal={toggleCertificateModal}
        certificateModalOpen={certificateModalOpen}
        openSnackbar={openSnackbar}
        setLoadingState={setLoadingState}
        fetchCertificate={fetchCertificate}
        fetchCertificates={fetchCertificates}
        modalActionType={modalActionType}
        inputToFocus={inputToFocus}
        selectedCertificate={selectedCertificate}
        windowDimensions={windowDimensions}
        userInfo={userInfo}
        certificates={certificates}
        receiverAddresses={receiverAddresses ? receiverAddresses : []}
        exporterAddresses={exporterAddresses ? exporterAddresses : []}
      />

      <ProductModal
        toggleProductModal={toggleProductModal}
        productModalOpen={productModalOpen}
        referenceData={referenceData}
        selectedCertificate={selectedCertificate}
        openSnackbar={openSnackbar}
        setLoadingState={setLoadingState}
        fetchCertificate={fetchCertificate}
        productId={selectedProduct?.id}
        modalActionType={modalActionType}
      />

      <LandingModal
        toggleLandingModal={toggleLandingModal}
        landingModalOpen={landingModalOpen}
        selectedCertificate={selectedCertificate}
        selectedProduct={selectedProduct}
        openSnackbar={openSnackbar}
        setLoadingState={setLoadingState}
        fetchCertificate={fetchCertificate}
        desktopView={desktopView}
      />

      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackbarType} sx={{ width: '100%' }}>
          {snackbarType === 'success' ? 'Aðgerð tókst' : 'Villa kom upp!'}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: '#fff' }}
        open={loading}
        style={{ zIndex: 100 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}

export default Home;
