import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";

// https://docs.sentry.io/platforms/javascript/user-feedback/configuration/
const feedback = Sentry.feedbackIntegration({
  colorScheme: "system",
  isNameRequired: true,
  isEmailRequired: true,
  showBranding: false,
  triggerLabel: "",
  buttonLabel: "Tilkynna villu",
  formTitle: "Tilkynna villu",
  nameLabel: "Nafn",
  namePlaceholder: "Nafnið þitt",
  emailLabel: "Netfang",
  emailPlaceholder: "Netfangið þitt",
  isRequiredLabel: "*",
  addScreenshotButtonLabel: "Bæta við skjámynd",
  messageLabel: "Skýring",
  messagePlaceholder: "Útskýrðu hvað gerðist",
  submitButtonLabel: "Senda villu",
  cancelButtonLabel: "Hætta við",
  successMessageText: "Takk fyrir að láta vita!"
});

Sentry.init({
  dsn: "https://74a5b2fe0d075841ac35e55ef17f0fd0@o4507412390346752.ingest.de.sentry.io/4507821674528848",
  integrations: [
    Sentry.browserTracingIntegration(),
    feedback,
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://red-field-06f581e03.4.azurestaticapps.net/", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  //<React.StrictMode>
    <App />
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
