import Step from '@mui/material/Step';
import styled from 'styled-components';

export const CertificateModalWrapper = styled.div`
`;

export const StyledBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fafafa;
  border: 0.1em solid #1976D2;
  border-radius: 1em;
  p: 4;
  padding: 1em;
  display: flex;
  flex-direction: column;
  max-height: 87%;
  overflow-y: auto;

  @media only screen and (min-width: 0px) {
    /* For mobile: */
    width: 90%;
  }

  @media only screen and (min-width: 1200px) {
    /* For desktop: */
    width: 60%;
  }

  @media only screen and (min-width: 1200px) {
    /* For desktop: */
    width: 38%;
  }
`;

export const InputFieldContainer = styled.div`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  width: 100%;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  padding-bottom: 1em;
`;

export const NewCertificateTitle = styled.h2`
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: space-between;
  padding-bottom: 0.5em;
  width: 100%;
`;

export const StyledStep = styled(Step)`
  padding-left: 0px !important;
  padding-right: 0px !important;
`;

export const TransportInfoContainer = styled.span`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 1em;
`;
