import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Avatar, Badge, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthService } from '../../Services/AuthService';
import { OrganizationNameCard, OrganizationNameHeader, UserInfoCardContainer, UserInfoHeaderContainer, UserNameCard } from './Header.styled';

const authService = new AuthService();
const settings = ['Skrá út'];

interface HeaderProps {
  desktopView: boolean;
  certificateSearch?: boolean;
}

const Header: FC<HeaderProps> = (props) => {

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [actorName, setActorName] = useState<string | null>(null);
  const [exporterName, setExporterName] = useState<string | null>(null);

  useEffect(() => {
    async function fetchUser() {
      const user = await authService.getUser();
      setExporterName(user?.profile?.name ?? null);
      if (user?.profile?.actor !== null) {
        setActorName((user?.profile?.actor as { name: string })?.name);
      }
    }
    fetchUser();
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const SmallAvatar = styled(Avatar)(() => ({
    width: 8,
    height: 8,
    backgroundColor: '#3a3b3c',
    border: '4px solid #3a3b3c'
  }));

  return (
    <AppBar position="static">
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', height: '1em', bgcolor: 'black' }} variant='dense'>
        <Box sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }} onClick={() => document.location.href = ''}>
          <img style={{ paddingRight: '1em', height: '2em', width: '2em', paddingTop: '1em' }} alt="logo" src="https://images.ctfassets.net/8k0h54kbe6bj/3W68zG8kVqVY7DU1qHxVEA/4d403503b05488cc8385578a92ef399e/fiskist-merki_2x.png"></img>
          <h3>{props?.certificateSearch ? 'Icelandic Catch Certificate' : 'Veiðivottorðakerfi'}</h3>
        </Box>

        {props?.certificateSearch !== true &&
          (
            <Box>
              <Tooltip title="">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {props.desktopView &&
                    <UserInfoHeaderContainer>
                      <OrganizationNameHeader>{exporterName}</OrganizationNameHeader>
                    </UserInfoHeaderContainer>
                  }
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <SmallAvatar>
                        <ExpandMoreIcon />
                      </SmallAvatar>
                    }
                  >
                    <Avatar sx={{ height: '2em', width: '2em', bgcolor: '#1976d2', fontSize: '0.65em', border: '2px solid #1976d2' }}>{exporterName?.length ? exporterName[0] : 'N'}</Avatar>
                  </Badge>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <UserInfoCardContainer>
                  <Avatar sx={{ left: 'calc(50% - 2em)', height: '4em', width: '4em', bgcolor: '#1976d2' }}>{exporterName?.length ? exporterName[0] : 'N'}</Avatar>
                  <OrganizationNameCard>{exporterName}</OrganizationNameCard>
                  {actorName !== null ? <UserNameCard>{actorName}</UserNameCard> : null}
                </UserInfoCardContainer>
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={() => authService.logout()}>
                    <LogoutIcon style={{ marginRight: '10px' }} />
                    <Typography textAlign="center" >{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )
        }

      </Toolbar>
    </AppBar>
  );
}

export default Header;
