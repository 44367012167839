import styled from 'styled-components';

export const ProductCardContainer = styled.div`
  min-height: 30em;
  height: 1px;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 1em;
  flex: 1;
`;

export const ListItem = styled.div`
  padding: 1em;
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 3em;
`;

export const CardData = styled.span`
  color: #1e2022;
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 0.8em;
`;