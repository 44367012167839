import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthService } from '../../Services/AuthService';
import { getCurrentUser } from '../../Services/VeidivottordAPIService';

const SignInOidc = () => {
  const authService = new AuthService();
  const navigate = useNavigate();

  const initialErrorState = {
    error: false,
    noAccess: false,
    errorMessage: "Ekki tókst að skrá þig inn."
  };

  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(initialErrorState);

  // Log user in and redirect to home page.
  const handleLoginChange = async () => {
    try {
      await authService.completeAuthentication();
      if (authService.isLoggedIn()) {
        try {
          const user = await getCurrentUser(setLoading);
          if (user) {
            navigate('/veidivottord');
          }
        } catch (err) {
          const user = await authService.getUser();

          setState({
            error: true,
            noAccess: true,
            errorMessage: user?.profile?.name + " (" + user?.profile?.nationalId + ") hefur ekki aðgang að kerfinu."
          });

          authService.logoutSilent();
        }
      } else {
        authService.renewToken();
      }
    } catch (err) {
      authService.logoutSilent();
      authService.login();
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleLoginChange();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#f4f4f4"
      gap={2}
    >
      {!state.error && (<p>Skrái þig inn...</p>)}
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {state.error && (
            <p style={{ color: 'red', fontWeight: 'bold' }}>{state.errorMessage}</p>
          )}
          {state.noAccess && (
            <span style={{ textAlign: 'center' }}>
              <p>Sækja þarf um aðgang hjá Fiskistofu.</p>
              <a href="#" onClick={() => authService.logout()}>Ýttu hér til þess að skoða leiðbeiningar.</a>
            </span>
          )}
        </>
      )}
    </Box>
  );
};

export default SignInOidc;