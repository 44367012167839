import { User, UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";

export class AuthService {
  public manager: UserManager;
  private user: User | null = null;

  constructor() {
    // https://github.com/IdentityModel/oidc-client-js/wiki
    const settings: UserManagerSettings = {
      authority: '/', // Any value is fine.
      client_id: '@fiskistofa.is/veidivottord',
      redirect_uri: process.env.REACT_APP_REDIRECT_URI ? process.env.REACT_APP_REDIRECT_URI : '',
      response_type: "code",
      loadUserInfo: false,
      automaticSilentRenew: true,
      post_logout_redirect_uri: `https://island.is/veidivottord`,
      silent_redirect_uri: `https://island.is/veidivottord`,
      scope: 'openid profile offline_access @fiskistofa.is/veidivottord',
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        token_endpoint: `${process.env.REACT_APP_API_BASE_URL}connect/token`,
        authorization_endpoint: `${process.env.REACT_APP_IDENTITY_SERVER_URL}connect/authorize`,
        userinfo_endpoint: `${process.env.REACT_APP_IDENTITY_SERVER_URL}connect/userinfo`,
        end_session_endpoint: `${process.env.REACT_APP_IDENTITY_SERVER_URL}connect/endsession`
      }
    };
    this.manager = new UserManager(settings);

    // checks if refreshing token resulted in a error because the refresh token has expired and logout the user.
    // tokens lifetime can be manipulated here: https://island.is/stjornbord/
    this.manager.events.addSilentRenewError((error) => {
      this.user = null;
      this.manager.removeUser();
      this.logout();
    })
  }

  isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  getUser(): Promise<User | null> {
    return this.manager.getUser();
  }

  getAuthorizationHeaderValue(): string {
    return `${this.user?.token_type} ${this.user?.access_token}`;
  }

  login(): Promise<void> {
    return this.manager.signinRedirect();
  }

  completeAuthentication(): Promise<void> {
    return this.manager.signinRedirectCallback().then((user: User) => {
      this.user = user;
    });
  }

  renewToken(): Promise<User | null> {
    return this.manager.signinSilent();
  }

  logout(): Promise<void> {
    return this.manager.signoutRedirect();
  }

  logoutSilent(): void {
    this.manager.signoutSilent();
  }
}