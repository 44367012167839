import { FC, useEffect } from 'react';
import * as React from 'react';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from '@mui/material/DialogTitle';
import CardContent from '@mui/material/CardContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ContentCopy from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DialogContentText from '@mui/material/DialogContentText';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridToolbarContainer } from '@mui/x-data-grid';
import Product from '../../../../Models/Interfaces/Product';
import Certificate from '../../../../Models/Interfaces/Certificate';
import ActionTypes from '../../../../Constants/ActionTypes';
import { deleteProduct } from '../../../../Services/VeidivottordAPIService';
import { CardContainer, CardData, InputRow, ListItem, ProductCardContainer } from './CertificateProductInfo.styled';
import WeightUnits from '../../../../Constants/WeightUnits';

interface CertificateProductInfoProps {
  selectedCertificate: Certificate | null;
  toggleProductModal: (actionType?: string) => void;
  toggleLandingModal: () => void;
  selectedProduct: Product | undefined;
  handleProductSelect: (productId: GridRowId | undefined) => void;
  openSnackbar: (statusCode: number) => void;
  setLoadingState: (loadingState: boolean) => void;
  fetchCertificate: (certificateId: number) => void;
  windowDimensions: { width: number, height: number };
}

const CertificateProductInfo: FC<CertificateProductInfoProps> = (props) => {

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [totalConnectedLandingCountString, setTotalConnectedLandingCountString] = React.useState('');

  const toggleDeleteDialog = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  };

  const getConnectedLandingCount = (row: Product) => {
    // Count by fishTypeId if certificate is new, otherwise count by productId.
    return props?.selectedCertificate?.landings
      ? props?.selectedCertificate?.fromDotNetAPI === false
        ? props?.selectedCertificate?.landings.filter(landing => landing.fishType.id === row?.fishType?.id)?.length
        : props?.selectedCertificate?.landings.filter(landing => landing.productId === row?.id)?.length
      : 0
  }

  const getConnectedLandingCountString = (row: any) => {
    const connectedLandingCount = getConnectedLandingCount(row);
    return connectedLandingCount === 1 ? connectedLandingCount + ' löndun' : connectedLandingCount + ' landanir';
  }

  const getTotalConnectedLandingCountString = () => {
    const connectedLandingCount = props.selectedCertificate?.products && props.selectedCertificate?.products.reduce((a, b) => a + getConnectedLandingCount(b), 0);
    if (connectedLandingCount === undefined) return;
    setTotalConnectedLandingCountString(connectedLandingCount === 1 ? connectedLandingCount + ' löndun' : connectedLandingCount + ' landanir');
  }

  const isProductHeavierThanLandings = (product: Product) => {
    const connectedLandingWeight = getConnectedLandingWeight(product);

    const kgToLbConversion = 0.45359237;
    const kgToOzConversion = 0.028349523;

    // The product.nettoWeight needs to be converted to kg if it's not already.
    // The connectedLandingWeight is always in kg.
    if (product.weightUnit.id === WeightUnits.G) {
      return product.nettoWeight > (connectedLandingWeight * 1000);
    } else if (product.weightUnit.id === WeightUnits.KG) {
      return product.nettoWeight > connectedLandingWeight;
    } else if (product.weightUnit.id === WeightUnits.LB) {
      return product.nettoWeight > (connectedLandingWeight / kgToLbConversion);
    } else if (product.weightUnit.id === WeightUnits.OZ) {
      return product.nettoWeight > (connectedLandingWeight / kgToOzConversion);
    }
  }

  const doesProductHaveReversedLandings = (product: Product) => {
    return props.selectedCertificate?.landings?.some(landing => landing.productId === product.id && landing.isReversed);
  }

  // Does any closed landing have usedAmount greater than landed amount.
  const doesProductHaveInvalidWeightLanding = (product: Product) => {
    return props.selectedCertificate?.landings?.some(landing => landing.productId === product.id && landing.closedLanding && landing.usedAmount > landing.landedAmount);
  }

  const showProductWarning = (product: Product) => {
    return isProductHeavierThanLandings(product) || doesProductHaveReversedLandings(product) || doesProductHaveInvalidWeightLanding(product); 
  }

  const getProductWarningMessage = (product: Product) => {
    if (doesProductHaveReversedLandings(product)) {
      const textItems = [getConnectedLandingCountString(product), 'Einhverri tengdri löndun hefur verið breytt eða bakfært.'];
      return textItems.join('\n');
    }
    if (doesProductHaveInvalidWeightLanding(product)) {
      const textItems = [getConnectedLandingCountString(product), 'Einhver tengd löndun er með meira notað magn en heildarþyngd löndunar.'];
      return textItems.join('\n');
    }
    const textItems = [getConnectedLandingCountString(product), 'Þyngd vöru er meiri en þyngd tengdra landana.'];
    return textItems.join('\n');
  }

  useEffect(() => {
    getTotalConnectedLandingCountString();
  }, [props.selectedCertificate?.products]);

  const getConnectedLandingWeight = (row: any) => {
    // Count by fishTypeId if certificate is new, otherwise count by productId.
    return props?.selectedCertificate?.landings
      ? props?.selectedCertificate?.fromDotNetAPI === false
        ? 0
        : props?.selectedCertificate?.landings.filter(landing => landing.productId === row?.id).reduce((a, b) => a + (b.usedAmount || 0), 0)
      : 0
  }

  const handleDeleteProduct = async (productId: number | undefined) => {
    toggleDeleteDialog();
    const response = await deleteProduct(props.setLoadingState, productId);
    props.fetchCertificate(props.selectedCertificate?.id ? props.selectedCertificate.id : 0);
    props.openSnackbar(response.status);
  };

  const isEditable = !props.selectedCertificate?.status?.id && props.selectedCertificate?.fromDotNetAPI === true;
  const desktopView = props.windowDimensions.width > 1200;

  const productColumns: GridColDef[] = [
    {
      field: 'tarriffCode',
      headerName: 'Tollskrárnúmer',
      valueGetter: (value, row) => row?.tarriffCode?.code ?? '',
      renderCell: ({ row }) => (
        <ListItem>
          <CardData>{row?.tarriffCode?.code}</CardData>
        </ListItem>
      ),
      flex: 1,
    },
    {
      field: 'detailedDescription',
      headerName: 'Lýsing',
      valueGetter: (value, row) => row?.fishType?.description ?? '',
      renderCell: ({ row }) => (
        <ListItem>
          <CardData>{row?.fishType?.description}</CardData>
        </ListItem>
      ),
      flex: 1.25,
    },
    {
      field: 'fishType',
      headerName: 'Tegund',
      type: 'singleSelect',
      valueGetter: (value, row) => row?.fishType?.name ?? '',
      renderCell: ({ row }) => (
        <ListItem>
          <CardData>
            {row?.fishType?.name} / {row?.processType?.name ?? ''}
          </CardData>
        </ListItem>
      ),
      flex: 1,
    },
    {
      field: 'packagingType',
      headerName: 'Pakkningar',
      valueGetter: (value, row) => row?.packageAmount ?? 0,
      renderCell: ({ row }) => (
        <ListItem>
          <CardData>
            {row?.packageAmount} {row?.packagingType?.name ?? ''}
          </CardData>
        </ListItem>
      ),
      flex: 1,
    },
    {
      field: 'nettoWeight',
      headerName: 'Nettóþyngd vöru',
      valueGetter: (value, row) => row?.nettoWeight ?? 0,
      renderCell: ({ row }) => (
        <ListItem>
          <CardData>
            {row?.nettoWeight} {row?.weightUnit?.name ?? ''}
          </CardData>
        </ListItem>
      ),
      flex: 1,
    },
    {
      field: 'landanir',
      headerName: 'Tengdar landanir',
      minWidth: 480,
      flex: 1,
      valueGetter: (value, row) => getConnectedLandingWeight(row),
      renderCell: ({ row }) => (
        <ListItem>
          <CardData>
            <InputRow style={{ width: '8em' }}>
              <Tooltip
                title={
                  showProductWarning(row)
                    ? <div style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
                      {getProductWarningMessage(row)}
                    </div>
                    : getConnectedLandingCountString(row)
                }
                onClick={() => props.toggleLandingModal()}
              >
                <span>
                  <Button
                    sx={{ width: '100%', minWidth: '13em', textTransform: 'none' }}
                    variant={isEditable ? 'contained' : 'outlined'}
                    endIcon={isEditable ? <AddIcon /> : <VisibilityIcon />}
                    startIcon={showProductWarning(row) ? <ErrorIcon /> : null}
                  >
                    {getConnectedLandingCountString(row)}
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Afrita vöru">
                <span>
                  <Button
                    sx={{ width: '33%' }}
                    variant="outlined"
                    disabled={!isEditable}
                    onClick={() => props.toggleProductModal(ActionTypes.COPY_ACTION)}
                  >
                    <ContentCopy />
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Breyta vöru">
                <span>
                  <Button
                    sx={{ width: '33%' }}
                    variant="outlined"
                    disabled={!isEditable}
                    onClick={() => props.toggleProductModal(ActionTypes.EDIT_ACTION)}
                  >
                    <EditIcon />
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Eyða vöru">
                <span>
                  <Button
                    sx={{ width: '33%' }}
                    variant="outlined"
                    disabled={!isEditable}
                    onClick={() => toggleDeleteDialog()}
                    color="error"
                  >
                    <DeleteIcon />
                  </Button>
                </span>
              </Tooltip>
            </InputRow>
          </CardData>
        </ListItem>
      ),
    },
  ];

  return (
    <ProductCardContainer>
      {desktopView ? (
        <DataGrid
          rows={props?.selectedCertificate?.products ? props?.selectedCertificate?.products : []}
          columns={productColumns}
          slots={{
            noRowsOverlay: () => (
              <Stack height="90%" alignItems="center" justifyContent="center">
                {props?.selectedCertificate?.id !== undefined ? props?.selectedCertificate?.products?.length ? '' : 'Engar vörur á vottorði.' : 'Ekkert vottorð valið.'}
              </Stack>
            ),
            footer: () => (
              <Box sx={{ p: 1, display: 'flex', width: '100%', borderTop: '1px solid #e0e0e0', padding: 0 }}>
                <ListItem style={{ flex: 1 }}>
                  <CardData>
                    <b>Samtals</b>
                  </CardData>
                </ListItem>
                <ListItem style={{ flex: 1.25 }}>
                  <CardData>
                  </CardData>
                </ListItem>
                <ListItem style={{ flex: 1 }}>
                  <CardData>
                  </CardData>
                </ListItem>
                <ListItem style={{ flex: 1, borderLeft: '1px solid #e0e0e0', borderRight: '1px solid #e0e0e0' }}>
                  <CardData>
                    {props.selectedCertificate?.products ? props.selectedCertificate?.products.reduce((a, b) => a + (b.packageAmount || 0), 0) : '-'}
                    {/** If all packaging is of same type, show type */}
                    {props.selectedCertificate?.products?.length && props.selectedCertificate?.products.every((val, i, arr) => val.packagingType?.name === arr[0].packagingType?.name) ? ' ' + props.selectedCertificate?.products[0]?.packagingType?.name : ''}
                  </CardData>
                </ListItem>
                <ListItem style={{ flex: 1, borderRight: '1px solid #e0e0e0' }}>
                  <CardData>
                    {props.selectedCertificate?.products && props.selectedCertificate?.products.reduce((a, b) => a + (b.nettoWeight || 0), 0).toFixed(2)} {props.selectedCertificate?.products && props.selectedCertificate?.products[0]?.weightUnit?.name}
                  </CardData>
                </ListItem>
                <ListItem style={{ flex: 1, minWidth: 445 }}>
                    {/* While we decide on whether amount from landing will be required, comment this out. */}
                    {/* <CardData>
                    {props.selectedCertificate?.products ? props.selectedCertificate?.products.reduce((a, b) => a + getConnectedLandingWeight(b), 0) : '-'} kg ({totalConnectedLandingCountString})
                    </CardData> */}
                </ListItem>
              </Box>
            ),
            toolbar: () => (
              <GridToolbarContainer>
                <Button color="primary" size='large' startIcon={<AddIcon />} onClick={() => props.toggleProductModal(ActionTypes.CREATE_ACTION)} disabled={(props.selectedCertificate?.status?.id || props.selectedCertificate?.fromDotNetAPI === false || props.selectedCertificate?.id === undefined) ? true : false}>
                  Bæta við vöru
                </Button>
              </GridToolbarContainer>
            )
          }}
          onRowSelectionModelChange={(selectedRowId) => {
            props.handleProductSelect(selectedRowId?.at(0));
          }}
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important"
            },
            "&.MuiDataGrid-root .MuiDataGrid-row:focus-within": {
              backgroundColor: "#d9ebfc"
            },
            "&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "#d9ebfc"
            },
            '& .MuiDataGrid-cell': {
              padding: 0,
            },
            fontSize: '14px',
          }}
          style={{ width: '100%' }}
          getRowHeight={() => 'auto'}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              }
            }
          }}
          pageSizeOptions={
            [100]
          }
        />
      ) :
        (
          <CardContainer>
            {props?.selectedCertificate?.products ? props.selectedCertificate?.products.map((product, index) => (
              <Card sx={{ flex: '1 1 auto', width: '100%' }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {product?.tarriffCode?.code}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {product?.fishType?.name} / {product?.processType?.name}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {product?.fishType?.description}
                  </Typography>
                  <Typography variant="body2">
                    {product?.nettoWeight + " " + product?.weightUnit?.name}
                    <br />
                    {product?.packageAmount + " " + product?.packagingType?.name}
                  </Typography>

                  <CardData style={{ paddingTop: '1em', gap: '1em' }}>

                    <Tooltip
                      title={getConnectedLandingCountString(product)}
                      onClick={() => {
                        props.toggleLandingModal();
                        props.handleProductSelect(product.id);
                      }}>
                      <span>
                        <Button
                          sx={{ width: '100%', minWidth: '13em', textTransform: 'none' }}
                          variant={isEditable ? 'contained' : 'outlined'}
                          endIcon={isEditable ? <AddIcon /> : <VisibilityIcon />}
                          startIcon={(isProductHeavierThanLandings(product) || doesProductHaveReversedLandings(product)) ? <ErrorIcon /> : null}
                        >
                          {getConnectedLandingWeight(product) + ' kg'} / {getConnectedLandingCountString(product)}
                        </Button>
                      </span>
                    </Tooltip>

                    <span>
                      <Tooltip title="Afrita vöru">
                        <span>
                          <Button
                            sx={{ width: 'calc(33% - 0.666em)', marginRight: '1em' }}
                            variant='outlined'
                            disabled={!isEditable}
                            onClick={() => {
                              props.handleProductSelect(product.id);
                              props.toggleProductModal(ActionTypes.COPY_ACTION);
                            }}
                          >
                            <ContentCopy></ContentCopy>
                          </Button>
                        </span>
                      </Tooltip>
                      <Tooltip title="Breyta vöru">
                        <span>
                          <Button
                            sx={{ width: 'calc(33% - 0.666em)', marginRight: '1em' }}
                            variant='outlined'
                            disabled={!isEditable}
                            onClick={() => {
                              props.handleProductSelect(product.id);
                              props.toggleProductModal(ActionTypes.EDIT_ACTION);
                            }}
                          >
                            <EditIcon></EditIcon>
                          </Button>
                        </span>
                      </Tooltip>
                      <Tooltip title="Eyða vöru">
                        <span>
                          <Button
                            sx={{ width: 'calc(33% - 0.65em)' }}
                            variant='outlined'
                            disabled={!isEditable}
                            onClick={() => {
                              props.handleProductSelect(product.id);
                              toggleDeleteDialog();
                            }}
                            color='error'
                          >
                            <DeleteIcon></DeleteIcon>
                          </Button>
                        </span>
                      </Tooltip>
                    </span>
                  </CardData>
                </CardContent>
              </Card>
            )) : ''}
          </CardContainer>
        )
      }

      <Dialog
        open={deleteDialogOpen}
        onClose={toggleDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Eyða vöru"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ertu viss um að þú viljir eyða vörunni og tengdum löndunum?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDeleteDialog}>Hætta við</Button>
          <Button onClick={() => handleDeleteProduct(props?.selectedProduct?.id)} autoFocus>
            Samþykkja
          </Button>
        </DialogActions>
      </Dialog>

    </ProductCardContainer >
  );
}

export default CertificateProductInfo;