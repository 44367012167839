import styled from 'styled-components';

export const ActionConfirmationModalWrapper = styled.div`
`;

export const ConfirmationBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fafafa;
  border-radius: 1em;
  p: 4;
  display: flex;
  flex-direction: column;
  background-color: white;

  @media only screen and (min-width: 0px) {
    /* For mobile: */
    width: 90%;
  }

  @media only screen and (min-width: 1200px) {
    /* For desktop: */
    width: 25%;
  }
`;
