import styled from 'styled-components';

export const ConfirmedCertificatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  width: auto;

`;

export const CertificateHeader = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 1em;
`;

export const ConfirmationStatus = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  gap: 1em;
  margin: 0 auto;
`;