import React, { FC } from 'react';
import { Modal } from '@mui/material';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Certificate from '../../../../Models/Interfaces/Certificate';
import { copyCertificate, voidCertificate } from '../../../../Services/VeidivottordAPIService';
import { ActionConfirmationModalWrapper, ConfirmationBox } from './ActionConfirmationModal.styled';

interface ActionConfirmationModalProps {
  certificateStatusId: number | undefined;
  actionConfirmationModalOpen: boolean;
  fetchCertificate: (certificateId: number) => void;
  fetchCertificates: () => void;
  openSnackbar: (statusCode: number) => void;
  setLoadingState: (loadingState: boolean) => void;
  selectedCertificate: Certificate | null;
  voidSelectionModalOpen: boolean;
  copySelectionModalOpen: boolean;
  toggleVoidSelectionModal: () => void;
  toggleCopySelectionModal: () => void;
}

const ActionConfirmationModal: FC<ActionConfirmationModalProps> = (props) => {

  const handleRectifyCertificate = async () => {
    props.toggleVoidSelectionModal();
    const response = await voidCertificate(props.setLoadingState, props.selectedCertificate?.id);
    props.fetchCertificates();
    props.fetchCertificate(response.data);
    props.openSnackbar(response.status);
  };

  const handleCopyCertificate = async () => {
    props.toggleCopySelectionModal();
    const response = await copyCertificate(props.setLoadingState, props.selectedCertificate?.id);
    props.fetchCertificates();
    props.fetchCertificate(response.data);
    props.openSnackbar(response.status);
  };

  return (
    <ActionConfirmationModalWrapper>
      <Modal
        open={props.actionConfirmationModalOpen}
        onClose={() => {
          if (props.voidSelectionModalOpen) {
            props.toggleVoidSelectionModal();
          }
          if (props.copySelectionModalOpen) {
            props.toggleCopySelectionModal();
          }
        }}
      >
        <ConfirmationBox>
          {/* Void/rectify or copy certificate */}
          <React.Fragment>
            <DialogTitle>
              {props.copySelectionModalOpen && 'Afrita vottorð'}
              {props.voidSelectionModalOpen && 'Ógilda vottorð #' + props.selectedCertificate?.number}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {props.voidSelectionModalOpen && 'Afrit af vottorði útbúið til leiðréttingar.'}
                {props.voidSelectionModalOpen && <br></br>}
                {props.voidSelectionModalOpen && 'Tilvísun í þetta vottorð mun birtast á afriti.'}

                {props.copySelectionModalOpen && 'Grunnupplýsingar og vörur afritaðar á nýtt vottorð.'}
                {props.copySelectionModalOpen && <br></br>}
                {props.copySelectionModalOpen && 'Landanatengingar verða ekki afritaðar.'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.voidSelectionModalOpen ? props.toggleVoidSelectionModal : props.toggleCopySelectionModal}>
                Hætta við
              </Button>
              <Button onClick={props.voidSelectionModalOpen ? handleRectifyCertificate : handleCopyCertificate}>
                Samþykkja
              </Button>
            </DialogActions>
          </React.Fragment>
        </ConfirmationBox>
      </Modal>
    </ActionConfirmationModalWrapper >
  );
}

export default ActionConfirmationModal;