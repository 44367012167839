import React from 'react';
import { FC, useEffect, useState } from 'react';
import FlightIcon from '@mui/icons-material/Flight';
import BusinessIcon from '@mui/icons-material/Business'
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import { Box, Button, Chip, FormControlLabel, FormLabel, Modal, Radio, RadioGroup, Switch } from '@mui/material';
import { Autocomplete, FormControl, InputAdornment, InputLabel, ListItemIcon, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import User from '../../../../Models/Interfaces/User';
import Address from '../../../../Models/Interfaces/Address';
import Certificate from '../../../../Models/Interfaces/Certificate';
import ActionTypes from '../../../../Constants/ActionTypes';
import { AuthService } from '../../../../Services/AuthService';
import CertificateSubmit from '../../../../Models/SubmitModels/CertificateSubmit';
import TransportMethods from '../../../../Constants/TransportMethods';
import CertificateTypes from '../../../../Constants/CertificateTypes';
import { CertificateErrorModel } from '../../../../Models/ErrorModels/CertificateErrorModel';
import { CertificateModalWrapper, InputFieldContainer, InputRow, NewCertificateTitle, StyledBox } from './CertificateModal.styled';
import { createCertificate, editCertificate } from '../../../../Services/VeidivottordAPIService';

interface NewCertificateModalProps {
  toggleCertificateModal: () => void;
  certificateModalOpen: boolean;
  openSnackbar: (statusCode: number) => void;
  setLoadingState: (loadingState: boolean) => void;
  fetchCertificate: (certificateId: number) => void;
  fetchCertificates: () => void;
  modalActionType: string | undefined;
  inputToFocus: string | undefined;
  selectedCertificate: Certificate | null;
  windowDimensions: { width: number, height: number };
  userInfo: User | null;
  certificates: Certificate[] | null;
  receiverAddresses: Address[];
  exporterAddresses: Address[];
}

const CertificateModal: FC<NewCertificateModalProps> = (props) => {

  const [transportMethod, setTransportMethod] = useState<string>(TransportMethods.SHIP);
  const [certificateFormData, setCertificateFormData] = useState<CertificateSubmit>({} as CertificateSubmit);
  const [exporterAddressVisible, setExporterAddressVisible] = useState(false);
  const [errorModel, setErrorModel] = useState<CertificateErrorModel>({} as CertificateErrorModel);
  const [currentUserCompanyName, setCurrentUserCompanyName] = useState<string | undefined>('');

  const REFERENCE_NUMBER_MAX_LENGTH = 20;
  const TRANSPORTING_SHIP_MAX_LENGTH = 100;
  const FLIGHT_NUMBER_MAX_LENGTH = 100;
  const CONTAINER_NUMBER_MAX_LENGTH = 500;
  const TRACKING_NUMBER_MAX_LENGTH = 100;
  const RECEIVER_NAME_MAX_LENGTH = 50;
  const RECEIVER_ADDRESS_MAX_LENGTH = 500;
  const EXPORTER_NAME_MAX_LENGTH = 50;
  const EXPORTER_ADDRESS_MAX_LENGTH = 500;

  const toggleExporterAddressVisible = () => setExporterAddressVisible(!exporterAddressVisible);

  const handleChange = (name: string, value: string | number | null | undefined) => {
    setCertificateFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const getCurrentUser = async () => {
    const authService = new AuthService();
    const user = await authService.getUser();
    setCurrentUserCompanyName(user?.profile?.name);
  }

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    setExporterAddressVisible(false);

    // If certificate is set and it's an edit action, copy certificate data from selected certificate.
    if (props?.selectedCertificate !== null && props?.modalActionType === ActionTypes.EDIT_ACTION) {
      handleChange("referenceNumber", props.selectedCertificate?.referenceNumber);
      handleChange("transportingShip", props.selectedCertificate?.transportingShip);
      handleChange("flightNumber", props.selectedCertificate?.flightNumber);
      handleChange("containerNumber", props.selectedCertificate?.containerNumber);
      handleChange("trackingNumber", props.selectedCertificate?.trackingNumber);
      handleChange("exporterName", props.selectedCertificate.exporterName);
      handleChange("receiverName", props.selectedCertificate.receiverName);
      handleChange("exporterAddress", props.selectedCertificate?.exporterAddress);
      handleChange("receiverAddress", props.selectedCertificate?.receiverAddress);
      handleChange("typeId", props.selectedCertificate?.typeId);
    } else {
      setCertificateFormData({
        exporterAddress: props.exporterAddresses.length ? props.exporterAddresses[0].subjectAddress : "",
        exporterName: props.exporterAddresses.length ? props.exporterAddresses[0].subjectName : "",
        typeId: props.certificates?.length ? props.certificates[0].typeId : CertificateTypes.EU,
      } as CertificateSubmit);
    }
  }, [props.selectedCertificate, props.modalActionType, props.certificates, props.exporterAddresses]);

  useEffect(() => {
    // Reset error model.
    setErrorModel({} as CertificateErrorModel);

    // Initiate the type of the certificate as the same as selected.
    handleChange("typeId", props.selectedCertificate?.typeId);

    if (props.inputToFocus === 'exporterAddress') {
      setExporterAddressVisible(true);
    }

    if (props?.modalActionType === ActionTypes.EDIT_ACTION) {
      // In edit mode, this sets the transport method to airplane if flightNumber is set.
      if (props.selectedCertificate?.flightNumber !== null) {
        setTransportMethod(TransportMethods.AIRPLANE);
      } else {
        setTransportMethod(TransportMethods.SHIP);
      }
    } else if (props?.modalActionType === ActionTypes.CREATE_ACTION) {
      // In create mode, this sets the transport method to airplane if previous certificate has it set.
      if (props.certificates?.length && props.certificates[0].flightNumber !== null) {
        setTransportMethod(TransportMethods.AIRPLANE);
      } else {
        setTransportMethod(TransportMethods.SHIP);
      }
    }
  }, [props.selectedCertificate, props.modalActionType, props.certificates]);

  const handleSelectTransportMethod = (transportMethod: string) => {
    setTransportMethod(transportMethod);
    if (transportMethod === TransportMethods.SHIP) {
      handleChange("flightNumber", null);
    } else {
      handleChange("containerNumber", null);
    }
  }

  const handleSubmitCertificate = async () => {
    try {
      props.toggleCertificateModal();
      const response = await createCertificate(props.setLoadingState, certificateFormData);
      props.fetchCertificates();
      props.fetchCertificate(response.data);
      props.openSnackbar(response.status);
    } catch (e) {
      props.openSnackbar(500);
    }
  };

  const handleEditCertificate = async () => {
    try {
      props.toggleCertificateModal();
      const response = await editCertificate(props.setLoadingState, certificateFormData, props.selectedCertificate?.id);
      props.fetchCertificate(response.data);
      props.openSnackbar(response.status);
    } catch (e) {
      props.openSnackbar(500);
    }
  };

  return (
    <CertificateModalWrapper>
      <Modal
        open={props.certificateModalOpen}
        onClose={() => {
          if (props.modalActionType === ActionTypes.EDIT_ACTION) {
            props.toggleCertificateModal();
          } else {
            if (Object.entries(certificateFormData).some(([key, value]) => {
              return key !== 'exporterAddress' && key !== 'exporterName' && key !== 'typeId' && (value !== null && value !== '');
            })) {
              if (window.confirm('Ertu viss um að þú viljir hætta við?')) {
                props.toggleCertificateModal();
              }
            } else {
              props.toggleCertificateModal();
            }
          }
        }
        }
      >
        <StyledBox>

          <NewCertificateTitle>
            {props.modalActionType === ActionTypes.EDIT_ACTION ? "Breyta veiðivottorði" : "Nýtt veiðivottorð"}
            <Chip
              variant="outlined"
              color="primary"
              label="Grunnupplýsingar" />
          </NewCertificateTitle>
          <InputFieldContainer>
            <FormLabel sx={{ fontSize: '12px' }}>Áfangastaður vottorðs</FormLabel>
            <InputRow>
              <RadioGroup
                row
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  checked={certificateFormData?.typeId === CertificateTypes.EU}
                  value={certificateFormData?.typeId}
                  onClick={() => handleChange("typeId", CertificateTypes.EU)}
                  control={<Radio />}
                  label={
                    <span>
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANBAMAAABbflNtAAAAMFBMVEUAM5kiR4MIOJKqmi0tTnoZQogGNpRyeU8LOpFGXWyvnivCqSEBM5dpcVOXjjibkDZC0N4wAAAASUlEQVQI12NgQAUcDTAWl/hFAygztIEjFMLiBAoxTwAz2UBEAph5WoGBgWkPmkkMLB8YGPgcIKYqMjAIKUDNdS+BmctwUABNDwCX4AkbzIMR+gAAAABJRU5ErkJggg=="
                        width="20"
                        alt="EU / EES"
                        style={{ marginRight: '0.5em' }}
                      />
                      EU / EES
                    </span>
                  }
                />
                <FormControlLabel
                  checked={certificateFormData?.typeId === CertificateTypes.USA}
                  value={certificateFormData?.typeId}
                  onClick={() => handleChange("typeId", CertificateTypes.USA)}
                  control={<Radio />}
                  label={
                    <span>
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALBAMAAACNJ7BwAAAAMFBMVEXovcLBS1rQd4LUg43jsLZSTnzirbTPdIDEVWNeXYfVhY9lZIxKRHNWVYHUhI7jsLe2+MkaAAAARUlEQVQI12OIORpzVBAMGKJ3zt6aBgZg5qtVQLCOIfbq3avlYMAwc+bOmQwQANLWAQYMSEAJDhgeG8MAwxcXKHBjEIQDADRVI7/3XjC6AAAAAElFTkSuQmCC"
                        width="20"
                        alt="USA"
                        style={{ marginRight: '0.5em' }}
                      />
                      USA
                    </span>
                  }
                />
                <FormControlLabel
                  checked={certificateFormData?.typeId === CertificateTypes.JAPAN}
                  value={certificateFormData?.typeId}
                  onClick={() => handleChange("typeId", CertificateTypes.JAPAN)}
                  control={<Radio />}
                  label={
                    <span>
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANBAMAAABbflNtAAAAG1BMVEX///+8AC3EH0bJM1fXaIL66+7hj6Ltusa9BzJnmT8QAAAAL0lEQVQI12NgwAnK0mGsYEFBUyjTUVBQBMpUFBQUgjIbBQUlMEWR1CKZgGQuJgAAgvAFYoodbs4AAAAASUVORK5CYII="
                        width="20"
                        alt="Japan"
                        style={{ marginRight: '0.5em' }}
                      />
                      Japan
                    </span>
                  }
                />
              </RadioGroup>
            </InputRow>

            <InputRow>
              <TextField
                onChange={(e) => {
                  handleChange("referenceNumber", e.target.value);
                  setErrorModel({
                    ...errorModel, referenceNumber: {
                      error: e.target.value.length > REFERENCE_NUMBER_MAX_LENGTH ? true : false,
                      message: e.target.value.length > REFERENCE_NUMBER_MAX_LENGTH ? 'Tilvísunarnúmer má ekki vera lengra en 20 stafir.' : '',
                    }
                  });
                }}
                value={
                  certificateFormData?.referenceNumber
                    ? certificateFormData?.referenceNumber
                    : ""
                }
                sx={{ width: '100%' }}
                label="Tilvísunarnúmer"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContentPasteIcon />
                    </InputAdornment>
                  ),
                }}
                autoFocus={props.inputToFocus === 'referenceNumber' ? true : false}
                autoComplete='off'
                error={errorModel?.referenceNumber?.error}
                helperText={errorModel?.referenceNumber?.message}
              />
            </InputRow>

            <InputRow>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel shrink={true}>
                  Útflutningsleið
                </InputLabel>
                <Select
                  label="Útflutningsleið"
                  sx={{ width: '100%' }}
                  value={transportMethod}
                  style={{ height: 56 }}
                  notched={true}
                >
                  <MenuItem
                    value={TransportMethods.SHIP}
                    onClick={() => handleSelectTransportMethod(TransportMethods.SHIP)}
                  >
                    <ListItemIcon>
                      <DirectionsBoatFilledIcon />
                    </ListItemIcon>
                    Flutningsskip
                  </MenuItem>
                  <MenuItem
                    value={TransportMethods.AIRPLANE}
                    onClick={() => handleSelectTransportMethod(TransportMethods.AIRPLANE)}
                  >
                    <ListItemIcon>
                      <FlightIcon />
                    </ListItemIcon>
                    Flugvél
                  </MenuItem>
                </Select>
              </FormControl>
            </InputRow>

            <InputRow>
              {transportMethod === TransportMethods.SHIP && (
                <TextField
                  onChange={(e) => {
                    handleChange("transportingShip", e.target.value);
                    setErrorModel({
                      ...errorModel, transportingShip: {
                        error: e.target.value.length > TRANSPORTING_SHIP_MAX_LENGTH ? true : false,
                        message: e.target.value.length > TRANSPORTING_SHIP_MAX_LENGTH ? 'Nafn flutningsskip má ekki vera lengra en 100 stafir.' : '',
                      }
                    });
                  }}
                  value={
                    certificateFormData?.transportingShip
                      ? certificateFormData?.transportingShip
                      : ""
                  }
                  sx={{ width: '100%' }}
                  label="Nafn flutningsskips"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DirectionsBoatFilledIcon />
                      </InputAdornment>
                    ),
                  }}
                  autoFocus={props.inputToFocus === 'transportingShip' ? true : false}
                  autoComplete='off'
                  error={errorModel?.transportingShip?.error}
                  helperText={errorModel?.transportingShip?.message}
                />)
              }
              {transportMethod === TransportMethods.AIRPLANE && (
                <TextField
                  onChange={(e) => {
                    handleChange("flightNumber", e.target.value);
                    setErrorModel({
                      ...errorModel, flightNumber: {
                        error: e.target.value.length > FLIGHT_NUMBER_MAX_LENGTH ? true : false,
                        message: e.target.value.length > FLIGHT_NUMBER_MAX_LENGTH ? 'Flugnúmer má ekki vera lengra en 100 stafir.' : '',
                      }
                    });
                  }
                  }
                  value={
                    certificateFormData?.flightNumber
                      ? certificateFormData?.flightNumber
                      : ""
                  }
                  sx={{ width: '100%' }}
                  label="Flugnúmer"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FlightIcon />
                      </InputAdornment>
                    ),
                  }}
                  autoFocus={props.inputToFocus === 'flightNumber' ? true : false}
                  error={errorModel?.flightNumber?.error}
                  helperText={errorModel?.flightNumber?.message}
                />)
              }
            </InputRow>

            {transportMethod === TransportMethods.SHIP && (
              <InputRow>
                <TextField
                  onChange={(e) => {
                    handleChange("containerNumber", e.target.value);
                    setErrorModel({
                      ...errorModel, containerNumber: {
                        error: e.target.value.length > CONTAINER_NUMBER_MAX_LENGTH ? true : false,
                        message: e.target.value.length > CONTAINER_NUMBER_MAX_LENGTH ? 'Gámanúmer má ekki vera lengra en 500 stafir.' : '',
                      }
                    });
                  }
                  }
                  value={
                    certificateFormData?.containerNumber
                      ? certificateFormData?.containerNumber
                      : ""
                  }
                  sx={{ width: '100%' }}
                  label="Gámanúmer"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalShippingIcon />
                      </InputAdornment>
                    ),
                  }}
                  autoFocus={props.inputToFocus === 'containerNumber' ? true : false}
                  autoComplete='off'
                  error={errorModel?.containerNumber?.error}
                  helperText={errorModel?.containerNumber?.message}
                />
              </InputRow>
            )}

            <InputRow>
              <TextField
                onChange={(e) => {
                  handleChange("trackingNumber", e.target.value)
                  setErrorModel({
                    ...errorModel, trackingNumber: {
                      error: e.target.value.length > TRACKING_NUMBER_MAX_LENGTH ? true : false,
                      message: e.target.value.length > TRACKING_NUMBER_MAX_LENGTH ? 'Farmbréfsnúmer má ekki vera lengra en 100 stafir.' : '',
                    }
                  });
                }
                }
                value={
                  certificateFormData?.trackingNumber
                    ? certificateFormData?.trackingNumber
                    : ""
                }
                sx={{ width: '100%' }}
                label="Farmbréfsnúmer"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ManageSearchIcon />
                    </InputAdornment>
                  ),
                }}
                autoFocus={props.inputToFocus === 'trackingNumber' ? true : false}
                error={errorModel?.trackingNumber?.error}
                helperText={errorModel?.trackingNumber?.message}
              />
            </InputRow>

            <InputRow>
              <Autocomplete
                onChange={(e, value) => {
                  if (typeof value !== 'string') {
                    handleChange("receiverName", value?.subjectName);
                    handleChange("receiverAddress", value?.subjectAddress);
                    setErrorModel({
                      ...errorModel, receiverName: {
                        error: value?.subjectName && value.subjectName?.length > RECEIVER_NAME_MAX_LENGTH ? true : false,
                        message: value?.subjectName && value?.subjectName?.length > RECEIVER_NAME_MAX_LENGTH ? 'Nafn móttakanda má ekki vera lengra en 50 stafir.' : '',
                      }
                    });
                    setErrorModel({
                      ...errorModel, receiverAddress: {
                        error: value?.subjectAddress && value.subjectAddress?.length > RECEIVER_ADDRESS_MAX_LENGTH ? true : false,
                        message: value?.subjectAddress && value?.subjectAddress?.length > RECEIVER_ADDRESS_MAX_LENGTH ? 'Heimilisfang móttakanda má ekki vera lengra en 500 stafir.' : '',
                      }
                    });
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if (typeof newInputValue === 'string') {
                    handleChange("receiverName", newInputValue);
                    setErrorModel({
                      ...errorModel, receiverName: {
                        error: newInputValue?.length > RECEIVER_NAME_MAX_LENGTH ? true : false,
                        message: newInputValue?.length > RECEIVER_NAME_MAX_LENGTH ? 'Nafn móttakanda má ekki vera lengra en 50 stafir.' : '',
                      }
                    });
                  }
                }}
                value={
                  certificateFormData?.receiverName
                    ? certificateFormData?.receiverName
                    : ""
                }
                disablePortal
                options={props.receiverAddresses}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option ? option : '';
                  } else {
                    return option.subjectName ? option.subjectName : '';
                  }
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={props.id}>
                      {option.subjectName}
                    </li>
                  );
                }}
                sx={{ width: '100%' }}
                clearOnBlur={false}
                freeSolo
                renderInput={(params) => <TextField
                  label="Nafn móttakanda"
                  {...params}
                  required
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <BusinessIcon />
                      </InputAdornment>
                    )
                  }}
                  autoFocus={props.inputToFocus === 'receiverName' ? true : false}
                  error={errorModel?.receiverName?.error}
                  helperText={errorModel?.receiverName?.message}
                />
                }
              />
            </InputRow>

            <InputRow>
              <Autocomplete
                onChange={(e, value) => {
                  if (typeof value !== 'string') {
                    handleChange("receiverAddress", value?.subjectAddress);
                    setErrorModel({
                      ...errorModel, receiverAddress: {
                        error: value?.subjectAddress && value.subjectAddress?.length > RECEIVER_ADDRESS_MAX_LENGTH ? true : false,
                        message: value?.subjectAddress && value?.subjectAddress?.length > RECEIVER_ADDRESS_MAX_LENGTH ? 'Heimilisfang móttakanda má ekki vera lengra en 500 stafir.' : '',
                      }
                    });
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if (typeof newInputValue === 'string') {
                    handleChange("receiverAddress", newInputValue);
                    setErrorModel({
                      ...errorModel, receiverAddress: {
                        error: newInputValue?.length > RECEIVER_ADDRESS_MAX_LENGTH ? true : false,
                        message: newInputValue?.length > RECEIVER_ADDRESS_MAX_LENGTH ? 'Heimilisfang móttakanda má ekki vera lengra en 500 stafir.' : '',
                      }
                    });
                  }
                }}
                value={
                  certificateFormData?.receiverAddress
                    ? certificateFormData?.receiverAddress
                    : ""
                }
                disablePortal
                options={props.receiverAddresses}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option ? option : '';
                  } else {
                    return option.subjectAddress ? option.subjectAddress : '';
                  }
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={props.id}>
                      {option.subjectAddress}
                    </li>
                  );
                }}
                sx={{ width: '100%' }}
                clearOnBlur={false}
                freeSolo
                renderInput={(params) => <TextField
                  multiline
                  rows={4}
                  key={params.id}
                  required
                  label="Heimilisfang móttakanda"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <BusinessIcon />
                      </InputAdornment>
                    )
                  }}
                  error={errorModel?.receiverAddress?.error}
                  helperText={errorModel?.receiverAddress?.message}
                />
                }
              />
            </InputRow>

            {exporterAddressVisible && (
              <React.Fragment>
                <InputRow>
                  <Autocomplete
                    onChange={(e, value) => {
                      if (typeof value !== 'string') {
                        handleChange("exporterName", value?.subjectName);
                        setErrorModel({
                          ...errorModel, exporterName: {
                            error: value?.subjectName && value.subjectName?.length > EXPORTER_NAME_MAX_LENGTH ? true : false,
                            message: value?.subjectName && value?.subjectName?.length > EXPORTER_NAME_MAX_LENGTH ? 'Nafn útflytjanda má ekki vera lengra en 50 stafir.' : '',
                          }
                        });
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (typeof newInputValue === 'string') {
                        handleChange("exporterName", newInputValue);
                        setErrorModel({
                          ...errorModel, exporterName: {
                            error: newInputValue?.length > EXPORTER_NAME_MAX_LENGTH ? true : false,
                            message: newInputValue?.length > EXPORTER_NAME_MAX_LENGTH ? 'Nafn útflytjanda má ekki vera lengra en 50 stafir.' : '',
                          }
                        });
                      }
                    }}
                    disabled={props.userInfo?.isAdmin ? false : true}
                    defaultValue={props.exporterAddresses.length > 0 ? props.exporterAddresses[0] : null}
                    value={
                      currentUserCompanyName
                        ? currentUserCompanyName
                        : ""
                    }
                    disablePortal
                    options={props.exporterAddresses}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option ? option : '';
                      } else {
                        return option.subjectName ? option.subjectName : '';
                      }
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={props.id}>
                          {option.subjectName}
                        </li>
                      );
                    }}
                    sx={{ width: '100%' }}
                    clearOnBlur={false}
                    freeSolo
                    renderInput={(params) => <TextField
                      label="Útflytjandi"
                      {...params}
                      error={errorModel?.exporterName?.error}
                      helperText={errorModel?.exporterName?.message}
                    />
                    }
                  />
                </InputRow>

                <InputRow>
                  <Autocomplete
                    onChange={(e, value) => {
                      if (typeof value !== 'string') {
                        handleChange("exporterAddress", value?.subjectAddress);
                        setErrorModel({
                          ...errorModel, exporterAddress: {
                            error: value?.subjectAddress && value.subjectAddress?.length > EXPORTER_ADDRESS_MAX_LENGTH ? true : false,
                            message: value?.subjectAddress && value?.subjectAddress?.length > EXPORTER_ADDRESS_MAX_LENGTH ? 'Heimilisfang útflytjanda má ekki vera lengra en 500 stafir.' : '',
                          }
                        });
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (typeof newInputValue === 'string') {
                        handleChange("exporterAddress", newInputValue);
                        setErrorModel({
                          ...errorModel, exporterAddress: {
                            error: newInputValue?.length > EXPORTER_ADDRESS_MAX_LENGTH ? true : false,
                            message: newInputValue?.length > EXPORTER_ADDRESS_MAX_LENGTH ? 'Heimilisfang útflytjanda má ekki vera lengra en 500 stafir.' : '',
                          }
                        });
                      }
                    }}
                    value={
                      certificateFormData?.exporterAddress
                        ? certificateFormData?.exporterAddress
                        : ""
                    }
                    disablePortal
                    defaultValue={props.exporterAddresses.length > 0 ? props.exporterAddresses[0] : null}
                    options={props.exporterAddresses}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option ? option : '';
                      } else {
                        return option.subjectAddress ? option.subjectAddress : '';
                      }
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={props.id}>
                          {option.subjectAddress}
                        </li>
                      );
                    }}
                    sx={{ width: '100%' }}
                    clearOnBlur={false}
                    freeSolo
                    renderInput={(params) => <TextField
                      multiline
                      rows={4}
                      label="Heimilisfang útflytjanda"
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        inputProps: { ...params.inputProps, tabIndex: -1 },
                        startAdornment: (
                          <InputAdornment position='start'>
                            <BusinessIcon />
                          </InputAdornment>
                        )
                      }}
                      autoFocus={props.inputToFocus === 'exporterAddress' ? true : false}
                      error={errorModel?.exporterAddress?.error}
                      helperText={errorModel?.exporterAddress?.message}
                    />
                    }
                  />
                </InputRow>
              </React.Fragment>
            )}
          </InputFieldContainer>

          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 2, padding: 0 }}>
            <FormControlLabel control={<Switch size='small' sx={{ marginLeft: '0.5em' }} />} label="Breyta útflytjanda" onChange={() => toggleExporterAddressVisible()} />
            <Tooltip title={!Object.values(errorModel).every(error => error.error === false) || certificateFormData?.receiverAddress === undefined || certificateFormData?.receiverName === undefined ? "Upplýsingar móttakanda/útflytjanda þurfa að vera fylltar út." : ""} arrow>
              <span>
                <Button
                  onClick={props.modalActionType === ActionTypes.EDIT_ACTION ? handleEditCertificate : handleSubmitCertificate}
                  variant="contained"
                  disabled={!Object.values(errorModel).every(error => error.error === false) || certificateFormData?.receiverAddress === undefined || certificateFormData?.receiverName === undefined}
                >
                  {props.modalActionType === ActionTypes.EDIT_ACTION ? 'Breyta vottorði' : 'Búa til vottorð'}
                </Button>
              </span>
            </Tooltip>
          </Box>
        </StyledBox>
      </Modal>

    </CertificateModalWrapper >
  );
}

export default CertificateModal;