import styled from 'styled-components';

export const CertificateOverviewWrapper = styled.div`
  min-height: 100%;
  gap: 1em;
  display: flex;
  flex-direction: column;
  margin: 1em 1em 1em 0em;
  width: calc(75% - 2em);
  
  ::-webkit-scrollbar:vertical {
      width: 5px;
      height: 5px;
  }

  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-border-radius: 10px;
      border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  
  ::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(255, 255, 255, 0.3);
  }
`;

/*!
 * Bootstrap  v5.2.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
export const ContainerCard = styled.div`
  box-shadow: 0 0.375rem 0.75rem rgba(140,152,164,.075);
  --bs-card-spacer-y: 1.3125rem;
  --bs-card-spacer-x: 1.3125rem;
  --bs-card-title-spacer-y: 0.25rem;
  --bs-card-border-width: 0.0625rem;
  --bs-card-border-color: rgba(231, 234, 243, 0.7);
  --bs-card-border-radius: 0.75rem;
  --bs-card-box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
  --bs-card-inner-border-radius: 0.6875rem;
  --bs-card-cap-padding-y: 1.3125rem;
  --bs-card-cap-padding-x: 1.3125rem;
  --bs-card-cap-bg: transparent;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1.3125rem 1.3125rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);

  ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
  }

  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-border-radius: 10px;
      border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  
  ::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(255, 255, 255, 0.3);
  }
`;

/*!
 * Bootstrap  v5.2.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
export const Card = styled.div`
  box-shadow: 0 0.375rem 0.75rem rgba(140,152,164,.075);
  --bs-card-spacer-y: 1.3125rem;
  --bs-card-spacer-x: 1.3125rem;
  --bs-card-title-spacer-y: 0.25rem;
  --bs-card-border-width: 0.0625rem;
  --bs-card-border-color: rgba(231, 234, 243, 0.7);
  --bs-card-border-radius: 0.75rem;
  --bs-card-box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
  --bs-card-inner-border-radius: 0.6875rem;
  --bs-card-cap-padding-y: 1.3125rem;
  --bs-card-cap-padding-x: 1.3125rem;
  --bs-card-cap-bg: transparent;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1.3125rem 1.3125rem;
  --bs-card-group-margin: 0.75rem;
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  padding: 1em;
  flex: 1 1 auto;
`;

export const CardLabel = styled.div`
    text-transform: uppercase;
    color: #8c98a4;
    font-size: 0.8rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5em;
`;

export const CardData = styled.span`
    color: #1e2022;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
`;

export const ItemRow = styled.div`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    gap: 1em;
    flex-direction: row;
`;

export const CertificateHeader = styled.div`
    display: flex;
    justify-content: space-between;
    line-height: 1.5em;
    padding-bottom: 0.75em;
    flex-flow: row wrap;
`;

export const TitleFont = styled.div`
    font-size: 1.3em;
    font-weight: bold;
    display: flex;
    gap: 0.5em;
    line-height: 1.75em;
    align-items: center;
    flex-wrap: wrap;
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 0.8em;
        flex-wrap: wrap;

    @media only screen and (max-width: 1200px) {
        /* For mobile: */
        flex-wrap: wrap;
    }
`;

export const StatusContainer = styled.div`
    display: flex;
    gap: 1em;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 0.5em;
`;

export const ButtonWrapper = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e7eaf3;
  padding: 1em;

  h4 {
    margin-block: 0;
    vertical-align: middle;
  }

  line-height: 2.25em;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  padding-bottom: 1em;
`;

export const StyledBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fafafa;
  border: 0.1em solid #ffffff00;
  border-radius: 1em;
  p: 4;
  padding: 1em;
  display: flex;
  flex-direction: column;
  max-height: 80%;
  overflow-y: auto;

  @media only screen and (min-width: 0px) {
    /* For mobile: */
    width: 90%;
  }

  @media only screen and (min-width: 1200px) {
    /* For desktop: */
    width: 35%;
  }

  @media only screen and (min-width: 1200px) {
    /* For desktop: */
    width: 25%;
  }
  
`;

export const RectificationHistoryTitle = styled.h2`
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: space-between;
  padding-bottom: 0.5em;
`;

export const OuterContainer = styled.div`
  overflow-y: auto;
  gap: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5em;
  padding-top: 0.25em;
`;