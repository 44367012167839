import { FC, useEffect } from 'react';
import { AuthService } from '../../Services/AuthService';

interface LoginProps {}

const Login: FC<LoginProps> = () => {
  useEffect(() => {
    const authService = new AuthService();
    const login = () => {
      authService.login().then(() => {
      });
    };
    login();
  }, []);

  return <div></div>;
};

export default Login;