import React, { FC, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import ScaleIcon from '@mui/icons-material/Scale';
import DialogTitle from '@mui/material/DialogTitle';
import NumbersIcon from '@mui/icons-material/Numbers';
import SetMealIcon from '@mui/icons-material/SetMeal';
import CategoryIcon from '@mui/icons-material/Category';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ClearIcon from "@mui/icons-material/ClearOutlined";
import SignLanguage from '@mui/icons-material/SignLanguage';
import DescriptionIcon from '@mui/icons-material/Description';
import DialogContentText from '@mui/material/DialogContentText';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import { Autocomplete, Box, Button, Chip, IconButton, InputAdornment, Modal, TextField, Tooltip } from '@mui/material';
import WeightUnit from '../../../../Models/Interfaces/WeightUnit';
import Certificate from '../../../../Models/Interfaces/Certificate';
import TarriffCode from '../../../../Models/Interfaces/TarriffCode';
import ActionTypes from '../../../../Constants/ActionTypes';
import ReferenceData from '../../../../Models/Interfaces/ReferenceData';
import ProductSubmit from '../../../../Models/SubmitModels/ProductSubmit';
import ProductErrorModel from '../../../../Models/ErrorModels/ProductErrorModel';
import { addProduct, editProduct } from '../../../../Services/VeidivottordAPIService';
import { AddProductTitle, InputFieldContainer, InputRow, ProductModalWrapper, StyledBox } from './ProductModal.styled';

interface ProductModalProps {
  toggleProductModal: (actionType?: string) => void;
  productModalOpen: boolean;
  referenceData: ReferenceData | undefined;
  selectedCertificate: Certificate | null;
  openSnackbar: (statusCode: number) => void;
  setLoadingState: (loadingState: boolean) => void;
  fetchCertificate: (certificateId: number) => void;
  productId?: number;
  modalActionType?: string | undefined;
}

const ProductModal: FC<ProductModalProps> = (props) => {

  const [productFormData, setProductFormData] = useState<ProductSubmit>({} as ProductSubmit);
  const [defaultWeightUnit, setDefaultWeightUnit] = useState<WeightUnit | undefined | null>(null);
  const [errorModel, setErrorModel] = useState<ProductErrorModel>({} as ProductErrorModel);
  const [confirmWeightUnitEditDialogOpen, setConfirmWeightUnitEditDialogOpen] = useState(false);

  const FISHTYPE_DETAIL_DESCRIPTION_MAX_LENGTH = 100;
  const TARRIFF_CODE_MAX_LENGTH = 20;

  const openConfirmWeightUnitEditDialog = () => {
    setConfirmWeightUnitEditDialogOpen(true);
  };

  const handleCloseWeightUnitEditDialog = () => {
    setConfirmWeightUnitEditDialogOpen(false);
  };

  useEffect(() => {

    // Reset error model.
    setErrorModel({} as ProductErrorModel);

    // If productId is set and it's a copy/edit action, copy product data from selected product.
    if (props?.productId && (props?.modalActionType === ActionTypes.COPY_ACTION || props?.modalActionType === ActionTypes.EDIT_ACTION)) {
      const productToCopy = props.selectedCertificate?.products.find(product => product.id === props.productId);
      if (productToCopy) {
        handleChange("tarriffCode", productToCopy.tarriffCode?.code);
        handleChange("fishTypeDetailedDescription", productToCopy?.fishType?.description ? productToCopy.fishType.description : "");
        handleChange("fishTypeId", productToCopy.fishType?.id);
        handleChange("processTypeId", productToCopy.processType?.id);
        handleChange("packagingTypeId", productToCopy.packagingType?.id);
        handleChange("packageAmount", productToCopy.packageAmount);
        handleChange("nettoWeight", productToCopy.nettoWeight);
        handleChange("weightUnitId", productToCopy.weightUnit?.id);
      }
    } else {
      setProductFormData({} as ProductSubmit);
      findDefaultWeightUnit();
    }
  }, [props.selectedCertificate, props.productId, props.modalActionType]);

  const findDefaultWeightUnit = () => {
    if (props.selectedCertificate?.products?.length) {
      // Choose the same weight unit as first product in certificate.
      const weightUnit = props.selectedCertificate?.products[0].weightUnit;
      setDefaultWeightUnit(weightUnit);
      handleChange("weightUnitId", weightUnit?.id);
    } else {
      // Default selection is kg.
      const weightUnit = props.referenceData?.weightUnits.find(weightUnit => weightUnit.id === 2);
      setDefaultWeightUnit(weightUnit);
      handleChange("weightUnitId", weightUnit?.id);
    }
  }

  const handleChange = (name: string, value: string | number | null | undefined) => {
    setProductFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const allFieldsExist = () => {
    return productFormData?.tarriffCode &&
      productFormData?.fishTypeDetailedDescription &&
      productFormData?.fishTypeId &&
      productFormData?.processTypeId &&
      productFormData?.packagingTypeId &&
      productFormData?.packageAmount &&
      productFormData?.nettoWeight &&
      productFormData?.weightUnitId
      ? true : false;
  }

  const autoSelectTarriffValues = (selectedTarriffCode: TarriffCode | undefined | null) => {
    let fishTypeFound = false;
    let processTypeFound = false;

    props.referenceData?.fishTypes.forEach(fishType => {
      if (selectedTarriffCode?.description.toLowerCase().includes(fishType.name.toLowerCase())) {
        setProductFormData((prevFormData) => ({ ...prevFormData, 'fishTypeId': fishType.id }));
        fishTypeFound = true;
      }
    });
    props.referenceData?.processTypes.forEach(processType => {
      if (selectedTarriffCode?.description.toLowerCase().includes(processType.name.toLowerCase())) {
        setProductFormData((prevFormData) => ({ ...prevFormData, 'processTypeId': processType.id }));
        processTypeFound = true;
      }
    });

    // Autofocus next input field.
    if (fishTypeFound && processTypeFound) {
      const packagingTypeInput = document.getElementById('packagingTypeId');
      packagingTypeInput?.focus();
    } else if (fishTypeFound) {
      const processTypeInput = document.getElementById('processTypeId');
      processTypeInput?.focus();
    } else {
      const fishTypeInput = document.getElementById('fishTypeId');
      fishTypeInput?.focus();
    }
  }

  const handleSubmitProduct = async () => {
    // Warn user about weight unit change.
    if (props.selectedCertificate?.products?.length && productFormData.weightUnitId !== props.selectedCertificate?.products[0]?.weightUnit?.id) {
      openConfirmWeightUnitEditDialog();
    } else {
      submitProduct();
    }
  };

  const handleEditProduct = async () => {
    // Warn user about weight unit change.
    if (productFormData.weightUnitId !== props.selectedCertificate?.products.find(product => product.id === props.productId)?.weightUnit?.id) {
      openConfirmWeightUnitEditDialog();
    } else {
      submitEditProduct();
    }
  };

  const submitEditProduct = async () => {
    handleCloseWeightUnitEditDialog();
    props.toggleProductModal();
    const response = await editProduct(props.setLoadingState, productFormData, props.productId);
    props.fetchCertificate(props.selectedCertificate?.id ? props.selectedCertificate.id : 0);
    props.openSnackbar(response.status);
  };

  const submitProduct = async () => {
    handleCloseWeightUnitEditDialog();
    props.toggleProductModal();
    const response = await addProduct(props.setLoadingState, productFormData, props.selectedCertificate?.id);
    props.fetchCertificate(props.selectedCertificate?.id ? props.selectedCertificate.id : 0);
    props.openSnackbar(response.status);
  }

  return (
    <ProductModalWrapper>
      <Modal
        open={props.productModalOpen}
        onClose={() => {
          if (props.modalActionType === ActionTypes.EDIT_ACTION || props.modalActionType === ActionTypes.COPY_ACTION) {
            props.toggleProductModal();
          } else {
            if (Object.entries(productFormData).some(([key, value]) => {
              return key !== 'weightUnitId' && (value !== null && value !== '');
            })) {
              if (window.confirm('Ertu viss um að þú viljir hætta við?')) {
                props.toggleProductModal();
              }
            } else {
              props.toggleProductModal();
            }
          }
        }}
      >
        <StyledBox>

          <AddProductTitle>
            {props.modalActionType === ActionTypes.COPY_ACTION ? "Afrita vöru" : props.modalActionType === ActionTypes.EDIT_ACTION ? "Breyta vöru" : "Bæta við vöru"}
            <Chip
              variant="outlined"
              color="primary"
              label='Vöruupplýsingar'
            />
          </AddProductTitle>

          <InputFieldContainer>
            <React.Fragment>

              {/* Vöruupplýsingar */}
              <InputRow>
                <Autocomplete
                  disablePortal
                  onChange={(e, value) => {
                    if (typeof value !== 'string') {
                      handleChange("tarriffCode", value?.code);
                      handleChange("fishTypeDetailedDescription", value?.description);
                      autoSelectTarriffValues(value);
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if (typeof newInputValue === 'string') {
                      handleChange("tarriffCode", newInputValue);
                    }
                  }}
                  value={
                    productFormData?.tarriffCode
                      ? productFormData.tarriffCode
                      : null
                  }
                  options={props.referenceData?.tarriffCodes ? props.referenceData.tarriffCodes : []}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    } else {
                      return option.code + " - " + option.description
                    }
                  }}
                  sx={{ width: '100%' }}
                  clearOnBlur={false}
                  freeSolo
                  renderInput={(params) => <TextField
                    label="Tollskrárnúmer"
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      required: true,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <NumbersIcon />
                        </InputAdornment>
                      )
                    }}
                    onChange={(e) => {
                      handleChange("tarriffCode", e.target.value);
                      setErrorModel({
                        ...errorModel, tarriffCode: {
                          error: e.target.value.length > TARRIFF_CODE_MAX_LENGTH ? true : false,
                          message: e.target.value.length > TARRIFF_CODE_MAX_LENGTH ? 'Tollskrárnúmer má ekki vera lengra en 20 stafir.' : '',
                        }
                      });
                    }}
                    error={errorModel?.tarriffCode?.error}
                    helperText={errorModel?.tarriffCode?.message}
                    required
                  />
                  }
                />
              </InputRow>

              <InputRow>
                <TextField
                  onChange={(e) => {
                    handleChange("fishTypeDetailedDescription", e.target.value);
                    setErrorModel({
                      ...errorModel, fishTypeDetailedDescription: {
                        error: e.target.value.length > FISHTYPE_DETAIL_DESCRIPTION_MAX_LENGTH ? true : false,
                        message: e.target.value.length > FISHTYPE_DETAIL_DESCRIPTION_MAX_LENGTH ? 'Lýsing má ekki vera lengra en 100 stafir.' : '',
                      }
                    });
                  }}
                  value={
                    productFormData?.fishTypeDetailedDescription
                      ? productFormData?.fishTypeDetailedDescription
                      : ""
                  }
                  sx={{ width: '100%' }}
                  label="Nánari lýsing"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton onClick={() => handleChange("fishTypeDetailedDescription", "")} edge="end">
                        {productFormData?.fishTypeDetailedDescription ? <ClearIcon fontSize='small'></ClearIcon> : ''}
                      </IconButton>
                    )
                  }}
                  error={errorModel?.fishTypeDetailedDescription?.error}
                  helperText={errorModel?.fishTypeDetailedDescription?.message}
                  autoComplete='off'
                  required
                />
              </InputRow>

              <InputRow>
                <Autocomplete
                  disablePortal
                  onChange={(e, value) => handleChange("fishTypeId", value?.id)}
                  value={
                    productFormData?.fishTypeId
                      ? props.referenceData?.fishTypes.find(fishType => fishType.id === productFormData?.fishTypeId)
                      : null
                  }
                  slotProps={{
                    popper: {
                      disablePortal: false,
                    }
                  }}
                  options={props.referenceData?.fishTypes ? (props.modalActionType === ActionTypes.EDIT_ACTION && (props?.selectedCertificate?.landings?.length ?? 0) > 0) ? [] : props.referenceData.fishTypes : []}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: '100%' }}
                  id="fishTypeId"
                  renderInput={(params) => <TextField
                    label="Fisktegund"
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      required: true,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SetMealIcon />
                        </InputAdornment>
                      )
                    }}
                    required
                    disabled={props.modalActionType === ActionTypes.EDIT_ACTION && (props?.selectedCertificate?.landings?.length ?? 0) > 0}
                    title={props.modalActionType === ActionTypes.EDIT_ACTION && (props?.selectedCertificate?.landings?.length ?? 0) > 0 ? "Ekki er hægt að breya fisktegund ef landanir hafa verið tengdar við vöru." : ""}
                  />
                  }
                />
              </InputRow>

              <InputRow>
                <Autocomplete
                  disablePortal
                  onChange={(e, value) => handleChange("processTypeId", value?.id)}
                  value={
                    productFormData?.processTypeId
                      ? props.referenceData?.processTypes.find(processType => processType.id === productFormData?.processTypeId)
                      : null
                  }
                  slotProps={{
                    popper: {
                      disablePortal: false,
                    }
                  }}
                  options={props.referenceData?.processTypes ? props.referenceData.processTypes : []}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: '100%' }}
                  id="processTypeId"
                  renderInput={(params) => <TextField
                    label="Vinnsla"
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      required: true,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SignLanguage />
                        </InputAdornment>
                      )
                    }}
                    required
                  />
                  }
                />
              </InputRow>

              <InputRow>
                <Autocomplete
                  disablePortal
                  onChange={(e, value) => handleChange("packagingTypeId", value?.id)}
                  value={
                    productFormData?.packagingTypeId
                      ? props.referenceData?.packagingTypes.find(packagingType => packagingType.id === productFormData?.packagingTypeId)
                      : null
                  }
                  slotProps={{
                    popper: {
                      disablePortal: false,
                    }
                  }}
                  options={props.referenceData?.packagingTypes ? props.referenceData.packagingTypes : []}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: '100%' }}
                  id='packagingTypeId'
                  renderInput={(params) => <TextField
                    label="Pakkningar"
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      required: true,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <TakeoutDiningIcon />
                        </InputAdornment>
                      )
                    }}
                    required
                  />
                  }
                />
              </InputRow>

              <InputRow>
                <TextField
                  onChange={(e) => {
                    handleChange("packageAmount", e.target.value)
                    if (Number(e.target.value) <= 0) {
                      setErrorModel({
                        ...errorModel, packageAmount: {
                          error: true,
                          message: 'Fjöldi pakkninga þarf að vera fleiri en núll.',
                        }
                      });
                    } else if (!Number.isInteger(Number(e.target.value))) {
                      setErrorModel({
                        ...errorModel, packageAmount: {
                          error: true,
                          message: 'Aðeins heiltölur leyfðar.',
                        }
                      });
                    } else {
                      setErrorModel({
                        ...errorModel, packageAmount: {
                          error: false,
                          message: '',
                        }
                      });
                    }
                  }}
                  value={
                    productFormData?.packageAmount
                      ? productFormData.packageAmount
                      : ''
                  }
                  sx={{ width: '100%' }}
                  label="Fjöldi pakkninga"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CategoryIcon />
                      </InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  error={errorModel?.packageAmount?.error}
                  helperText={errorModel?.packageAmount?.message}
                  autoComplete='off'
                  required
                />
              </InputRow>

              <InputRow>
                <TextField
                  type="number"
                  onChange={(e) => {
                    handleChange("nettoWeight", e.target.value)
                    setErrorModel({
                      ...errorModel, nettoWeight: {
                        error: Number(e.target.value) <= 0 ? true : false,
                        message: Number(e.target.value) <= 0 ? 'Nettóþyng þarf að vera hærri en núll.' : '',
                      }
                    });
                  }}
                  value={
                    productFormData?.nettoWeight
                      ? productFormData.nettoWeight
                      : ''
                  }
                  sx={{ width: '100%' }}
                  label="Nettóþyngd (heildarþyngd vöru)"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ScaleIcon />
                      </InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  error={errorModel?.nettoWeight?.error}
                  helperText={errorModel?.nettoWeight?.message}
                  autoComplete='off'
                  required
                />

                <Autocomplete
                  disablePortal
                  onChange={(e, value) => handleChange("weightUnitId", value?.id)}
                  options={props.referenceData?.weightUnits ? props.referenceData.weightUnits : []}
                  defaultValue={defaultWeightUnit ? defaultWeightUnit : null}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: '100%' }}
                  renderInput={(params) => <TextField
                    label="Þyngdareining"
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      required: true,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <ScaleIcon />
                        </InputAdornment>
                      )
                    }}
                    required
                  />
                  }
                />
              </InputRow>

            </React.Fragment>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Tooltip title={!allFieldsExist() ? "Allar upplýsingar þurfa að vera fylltar út." : !Object.values(errorModel).every(error => error.error === false) ? "Vinsamlegast lagaðu villur." : ""} arrow>
                <span>
                  <Button
                    onClick={props.modalActionType === ActionTypes.EDIT_ACTION ? handleEditProduct : handleSubmitProduct}
                    variant="contained"
                    disabled={!Object.values(errorModel).every(error => error.error === false) || !allFieldsExist()}
                  >
                    {props.modalActionType === ActionTypes.COPY_ACTION ? "Afrita vöru" : props.modalActionType === ActionTypes.EDIT_ACTION ? "Breyta vöru" : "Bæta við vöru"}
                  </Button>
                </span>
              </Tooltip>
            </Box>

          </InputFieldContainer>

        </StyledBox>
      </Modal>

      {confirmWeightUnitEditDialogOpen &&
        <Dialog
          open={confirmWeightUnitEditDialogOpen}
          onClose={handleCloseWeightUnitEditDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Ertu viss um að þú viljir breyta þyngdareiningu?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Breyting á þyngdareiningu mun uppfæra allar vörur tengdar þessu vottorði.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseWeightUnitEditDialog}>Hætta við</Button>
            <Button onClick={() => props.modalActionType === ActionTypes.EDIT_ACTION ? submitEditProduct() : submitProduct()} autoFocus>
              Samþykkja
            </Button>
          </DialogActions>
        </Dialog>}

    </ProductModalWrapper >
  );
}

export default ProductModal;