import { Navigate, Route, Routes } from 'react-router-dom';
import Home from '../Pages/Home/Home';
import Login from '../SharedComponents/Login/Login';
import SigninOidc from '../SharedComponents/SigninOidc/SigninOidc';
import ConfirmedCertificates from '../Pages/ConfirmedCertficates/ConfirmedCertificates';

function RouteComponent() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="/veidivottord"
        element={
          <Home />
        }
      />
      <Route path="/signin-oidc" element={<SigninOidc />} />
      <Route path="/confirmed-certificates/:confirmedCertificateKey" element={<ConfirmedCertificates />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default RouteComponent;