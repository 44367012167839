import React, { useState } from 'react';
import { FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import GppBadIcon from '@mui/icons-material/GppBad';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import BusinessIcon from '@mui/icons-material/Business';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Button, Chip, IconButton, Stack, Theme, Tooltip, Typography } from '@mui/material';
import { BaseSingleInputFieldProps, DateValidationError, FieldSection } from '@mui/x-date-pickers/models';
import { DataGrid, GridColDef, GridLogicOperator, GridToolbarContainer, GridToolbarFilterButton, GridToolbarProps, ToolbarPropsOverrides } from '@mui/x-data-grid';
import User from '../../../../Models/Interfaces/User';
import Certificate from '../../../../Models/Interfaces/Certificate';
import ActionTypes from '../../../../Constants/ActionTypes';
import { ButtonWrapper, Card, CardData, CatchCertificateListWrapper, DateFont, ExporterImporterContainer, ListItem } from './CatchCertificateList.styled';
import CertificateStatuses from '../../../../Constants/CertificateStatuses';

interface CatchCertificateListProps {
  width: string;
  userInfo: User | null;
  certificatesLoading: boolean;
  certificates: Certificate[] | null;
  selectedCertificate: Certificate | null;
  windowDimensions: { width: number, height: number };
  fetchCertificates: (fromDate?: Date) => void;
  fetchCertificate: (certificateId: number) => void;
  toggleCertificateModal: (actionType?: string) => void;
}

const CatchCertificateList: FC<CatchCertificateListProps> = (props) => {

  const [dateFilterValue, setDateFilterValue] = React.useState<Dayjs | null>(props?.userInfo?.isAdmin ? dayjs().subtract(0, 'day') : dayjs().subtract(14, 'day'));
  const mobileView = props.windowDimensions.width <= 1200;

  const columns: GridColDef[] = [
    {
      field: 'creator',
      filterable: props.userInfo?.isAdmin ? true : false,
      headerName: 'Notandi',
      type: 'singleSelect',
      valueOptions: () => {
        const certificateCreatorNames: string[] = [];
        props.certificates?.forEach(certificate => {
          if (!certificateCreatorNames.includes(certificate.creator.fullName)) {
            certificateCreatorNames.push(certificate.creator.fullName);
          }
        });
        return certificateCreatorNames.sort();
      },
      flex: 1,
      colSpan: 42,
      valueGetter: (params: User ) => {
        return params?.fullName;
      },
      renderCell: (params) => (
        <ListItem style={{ backgroundColor: props?.selectedCertificate?.id === params?.row?.id ? '#c7dff1' : 'white' }}>
          <CardData>
            <ExporterImporterContainer>
              {params?.row?.exporterName ?
                <Stack direction="row" alignItems="center" gap={1}>
                  <BusinessIcon sx={{ fontSize: '15px' }} />
                  <Typography variant="body2">{props.userInfo?.isAdmin ? params?.row?.exporterName + '\n ⇾ ' + params?.row?.receiverName : params?.row?.receiverName}</Typography>
                </Stack>
                :
                ''}
              {params?.row?.referenceNumber ?
                <Stack direction="row" alignItems="center" gap={1}>
                  <ContentPasteIcon sx={{ fontSize: '15px' }} />
                  <Typography variant="body2">{params?.row?.referenceNumber}</Typography>
                </Stack>
                :
                ''}
            </ExporterImporterContainer>
            <div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip title={params?.row?.status?.id === CertificateStatuses.CONFIRMED ? 'Staðfest' : params?.row?.status?.id === CertificateStatuses.VOID ? 'Ógilt' : 'Óstaðfest'}>
                  <Chip
                    icon={params?.row?.status?.id === CertificateStatuses.CONFIRMED ? <GppGoodIcon />
                      : params?.row?.status?.id === CertificateStatuses.VOID ? <GppBadIcon />
                        : <GppMaybeIcon />
                    }
                    color={params?.row?.status?.id === CertificateStatuses.CONFIRMED ? 'success'
                      : params?.row?.status?.id === CertificateStatuses.VOID ? 'error'
                        : 'default'
                    }
                    size="small"
                    sx={{
                      "&.MuiChip-root .MuiChip-icon": {
                        marginRight: "-12px",
                      }
                    }}
                  />
                </Tooltip>
              </div>
              <DateFont>
                {dayjs(new Date(params?.row?.dateCreated ? params?.row?.dateCreated : '')).format('DD/MM/YYYY')}
              </DateFont>
            </div>
          </CardData>
        </ListItem>
      )
    },
    {
      field: 'number',
      type: 'number',
      filterable: true,
      headerName: 'Númer vottorðs'
    },
    {
      field: 'referenceNumber',
      type: 'string',
      filterable: true,
      headerName: 'Tilvísunarnúmer'
    },
    {
      field: 'transportingShip',
      type: 'singleSelect',
      valueOptions: () => {
        const transportingShips: string[] = [];
        props.certificates?.forEach(certificate => {
          if (!transportingShips.includes(certificate.transportingShip)) {
            transportingShips.push(certificate.transportingShip);
          }
        });
        return transportingShips.sort();;
      },
      filterable: true,
      headerName: 'Flutningsskip'
    },
    {
      field: 'containerNumber',
      type: 'string',
      filterable: true,
      headerName: 'Gámanúmer'
    },
    {
      field: 'flightNumber',
      type: 'singleSelect',
      valueOptions: () => {
        const flightNumbers: string[] = [];
        props.certificates?.forEach(certificate => {
          if (!flightNumbers.includes(certificate.flightNumber)) {
            flightNumbers.push(certificate.flightNumber);
          }
        });
        return flightNumbers.sort();
      },
      filterable: true,
      headerName: 'Flugnúmer'
    },
    {
      field: 'trackingNumber',
      type: 'string',
      filterable: true,
      headerName: 'Farmbréfsnúmer'
    },
    {
      field: 'receiverName',
      type: 'singleSelect',
      valueOptions: () => {
        const receiverNames: string[] = [];
        props.certificates?.forEach(certificate => {
          if (!receiverNames.includes(certificate.receiverName)) {
            receiverNames.push(certificate.receiverName);
          }
        });
        return receiverNames.sort();
      },
      filterable: true,
      headerName: 'Nafn viðtakanda'
    },
    {
      field: 'exporterName',
      type: 'singleSelect',
      valueOptions: () => {
        const exporterNames: string[] = [];
        props.certificates?.forEach(certificate => {
          if (!exporterNames.includes(certificate.exporterName)) {
            exporterNames.push(certificate.exporterName);
          }
        });
        return exporterNames.sort();;
      },
      filterable: true,
      headerName: 'Nafn útflytjanda'
    },
  ];

  interface CustomToolbarProps {
    setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  }

  const CustomToolbarWrapper = (props: GridToolbarProps & ToolbarPropsOverrides) => {
    const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

    return <CustomToolbar setFilterButtonEl={setFilterButtonEl} {...props} />;
  };

  interface ButtonFieldProps
    extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      false,
      DateValidationError
    > {
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  }

  function ButtonField(props: ButtonFieldProps) {
    const {
      setOpen,
      id,
      disabled,
      InputProps: { ref } = {},
    } = props;

    return (
      <Tooltip title="Stilla tímabil">
        <IconButton
          id={id}
          disabled={disabled}
          ref={ref}
          onClick={() => setOpen?.((prev) => !prev)}
        >
          <CalendarMonthIcon />
          <span style={{ paddingLeft: '0.5em' }}>
            <p style={{ fontSize: '12px', margin: 0 }}>{dateFilterValue?.format('DD/MM/YYYY') + " - " + dayjs().format('DD/MM/YYYY')}</p>
          </span>

        </IconButton>
      </Tooltip>
    );
  }

  function ButtonDatePicker(
    props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'>,
  ) {
    const [open, setOpen] = React.useState(false);

    return (
      <DatePicker
        slots={{ field: ButtonField, ...props.slots }}
        slotProps={{ field: { setOpen } as any }}
        {...props}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        label="Dagsetning frá"
      />
    );
  }

  function CustomToolbar({ setFilterButtonEl }: CustomToolbarProps) {
    return (
      <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <GridToolbarFilterButton ref={setFilterButtonEl} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <ButtonDatePicker
              value={dateFilterValue}
              onChange={(newValue) => {
                setDateFilterValue(newValue);
                props.fetchCertificates(new Date(newValue?.format('YYYY-MM-DD') as string));
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </GridToolbarContainer>
    );
  }

  return (
    <CatchCertificateListWrapper style={{ width: props.width, height: !mobileView ? '100%' : '' }}>
      <Card
        style={{
          margin: mobileView ? '0' : '',
          padding: mobileView ? '0' : '',
          borderRadius: mobileView ? '0' : '0.75rem',
          height: mobileView ? '100%' : '',
        }}>
        <ButtonWrapper style={{ margin: mobileView ? '1em' : '' }}>
          <h4>Veiðivottorð</h4>
          <Button
            variant="contained"
            endIcon={<AddIcon />}
            onClick={() => props.toggleCertificateModal(ActionTypes.CREATE_ACTION)}
          >
            Nýtt vottorð
          </Button>
        </ButtonWrapper>

        <DataGrid
          rows={props?.certificates ? props.certificates : []}
          columns={columns}
          columnVisibilityModel={{
            trackingNumber: false,
            flightNumber: false,
            containerNumber: false,
            transportingShip: false,
            certificateNumber: false,
            referenceNumber: false,
            exporterName: false,
            exporterAddress: false,
            receiverName: false,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            }
          }}
          pageSizeOptions={[100]}
          sx={{
            "&.MuiDataGrid-root": {
              paddingBottom: mobileView ? '4em' : '',
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "&.MuiDataGrid-root .MuiDataGrid-row:focus-within": {
              backgroundColor: "#d9ebfc"
            },
            "&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "#d9ebfc"
            },
            "&.MuiDataGrid-root .MuiDataGrid-virtualScroller": {
              overflowX: 'hidden',
            },
            "& .MuiDataGrid-cell": {
              padding: 0,
            },
            "& .MuiDataGrid-scrollbar": {
              zIndex: 0
            },
            "& .MuiDataGrid-columnSeparator": {
              display: 'none',
            }
          }}
          columnHeaderHeight={0}
          slots={{
            noRowsOverlay: () => (
              <Stack height="90%" alignItems="center" justifyContent="center">
                {props.certificatesLoading ? 'Sækir veiðivottorð...' : 'Engin veiðivottorð.'}
              </Stack>
            ),
            noResultsOverlay: () => (
              <Stack height="90%" alignItems="center" justifyContent="center">
                {props.certificatesLoading ? 'Leitar...' : 'Engin veiðivottorð fundust.'}
              </Stack>
            ),
            toolbar: CustomToolbarWrapper,
          }}
          onRowClick={(row) => props.fetchCertificate(Number(row.id))}
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          disableColumnMenu
          slotProps={{
            filterPanel: {
              logicOperators: [GridLogicOperator.And],
              columnsSort: 'asc',
              filterFormProps: {
                logicOperatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                },
                columnInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                operatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                valueInputProps: {
                  InputComponentProps: {
                    variant: 'outlined',
                    size: 'small',
                    label: ''
                  },
                },
                deleteIconProps: {
                  sx: {
                    '& .MuiSvgIcon-root': { color: '#d32f2f' },
                  },
                },
              },
              sx: {
                '& .MuiDataGrid-filterForm': { p: 2 },
                '& .MuiDataGrid-filterForm:nth-of-type(even)': {
                  backgroundColor: (theme: Theme) =>
                    theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                },
                '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                '.MuiNativeSelect-select option': {
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 400,
                  fontSize: '1rem',
                  lineHeight: '1.4375em',
                  letterSpacing: '0.00938em',
                  color: 'rgba(0, 0, 0, 0.87)',
                  boxSizing: 'border-box',
                  position: 'relative',
                  cursor: 'text',
                  display: 'inline-flex',
                  alignItems: 'center',
                  borderRadius: '4px',
                },
                maxWidth: "100vw"
              },
            },
          }}
          loading={props.certificatesLoading}
          localeText={{
            toolbarFilters: "Síur",
            toolbarFiltersTooltipHide: "Fela síur",
            toolbarFiltersTooltipShow: "Sýna síur",
            columnHeaderFiltersLabel: "Sía eftir",
            filterOperatorContains: "Inniheldur",
            filterOperatorDoesNotContain: "Inniheldur ekki",
            filterOperatorEquals: "Jafnt og",
            filterOperatorDoesNotEqual: "Ekki jafnt og",
            filterOperatorStartsWith: "Byrjar á",
            filterOperatorEndsWith: "Endar á",
            filterOperatorIs: "Er",
            filterOperatorNot: "Er ekki",
            filterOperatorAfter: "Eftir",
            filterOperatorOnOrAfter: "Á eða eftir",
            filterOperatorBefore: "Fyrir",
            filterOperatorOnOrBefore: "Á eða fyrir",
            filterOperatorIsEmpty: "Er tómt",
            filterOperatorIsNotEmpty: "Er ekki tómt",
            filterValueAny: "(einhver)",
            filterValueTrue: "satt",
            filterValueFalse: "ósatt",
            filterOperatorIsAnyOf: "Er einhver af",
          }}
        />
      </Card>
    </CatchCertificateListWrapper>
  );
}

export default CatchCertificateList;
